import React from 'react';
import styled from 'styled-components';
// Empty Wrapper to build the scroller ui

export default function Privacy() {
  return (
    <Wrapper>
      <></>
    </Wrapper>
  );
}

const Wrapper = styled.section`
  background-color: ${props => props.theme.lightGrey};
`;