import React, { useState, useEffect } from 'react';
import GoogleMap from '../GoogleMap';
import { StaticQuery, graphql, useStaticQuery } from 'gatsby';

import { StyledContainer, StyledMapImage, StyledLink } from './styles';

import MapContainerInfo from '../MapContainerInfo';

const MapContainer = props => {
  const data = useStaticQuery(graphql`
    query {
      london_map: file(relativePath: { eq: "london-map.png" }) {
        childImageSharp {
          fixed(width: 2500, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      newyork_map: file(relativePath: { eq: "newyork-map.png" }) {
        childImageSharp {
          fixed(width: 2500, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  const [locationView, changeLocation] = useState('london');
  const londonLatLng = { lat: 51.50788614475553, lng: -0.09491010215249314 };
  const newyorkLatLng = { lat: 40.708951, lng: -74.011997 };
  let locationCenter;

  useEffect(() => {
    fetch('https://ipinfo.io/json?token=c587bb5c131761')
      .then(r => r.json())
      .then(d => {
        setTimeout(() => {
          if (d.country === 'US') changeLocation('newyork');
          locationCenter = newyorkLatLng;
        }, 3600);
      })
      .catch(console.log);
  }, []);

  if (locationView === 'london') {
    locationCenter = londonLatLng;
  } else if (locationView === 'newyork') {
    locationCenter = newyorkLatLng;
  }

  return (
    <StyledContainer>
      {props.isIE && locationView === 'london' && (
        <StyledLink
          target="_blank"
          href="https://www.google.com/maps/place/Riverside+House,+2A+Southwark+Bridge+Rd,+London+SE1+9HA/@51.5077259,-0.0970988,17z/data=!3m1!4b1!4m5!3m4!1s0x487604a9c70c9f43:0xb5ca59f2f86b9f8c!8m2!3d51.5077259!4d-0.0949101"
        >
          <StyledMapImage src={data.london_map.childImageSharp.fixed.src} />
        </StyledLink>
      )}
      {props.isIE && locationView === 'newyork' && (
        <StyledLink
          target="_blank"
          href="https://www.google.com/maps/dir//Four+World+Trade+Center,+150+Greenwich+St,+New+York,+NY+10007,+USA/@40.7102889,-74.0144788,17z/data=!4m17!1m7!3m6!1s0x89c25a19fa24b37b:0xe82cd7d29692669c!2sFour+World+Trade+Center,+150+Greenwich+St,+New+York,+NY+10007,+USA!3b1!8m2!3d40.7102889!4d-74.0122901!4m8!1m0!1m5!1m1!1s0x89c25a19fa24b37b:0xe82cd7d29692669c!2m2!1d-74.0122901!2d40.7102889!3e2"
        >
          <StyledMapImage src={data.newyork_map.childImageSharp.fixed.src} />
        </StyledLink>
      )}
      {!props.isIE && <GoogleMap defaultCenter={locationCenter} />}
      <MapContainerInfo
        locationView={locationView}
        changeLocation={changeLocation}
      />
    </StyledContainer>
  );
};

export default MapContainer;
