import React from 'react';
import WindowWidthContext, { useWindowWidth } from '../../context/WindowWidthContext';
import { useSpring } from 'react-spring';

import RightArrow from '../../images/map-arrow-right.svg';
import LeftArrow from '../../images/map-arrow-left.svg';

import theme from '../Layout/theme';
import {
  StyledLocationPhone,
  StyledLocationTitle,
  StyledLocationInfo,
  StyledOtherOfficeContainer,
  StyledOtherOfficeLon,
  StyledOtherOfficeNY,
  StyledNextArrow,
  StyledBackArrow,
  StyledLocation,
  StyledLocationSplitInfo,
  LocationPanel,
} from './styles';

const MapContainerInfo = props => {
  let locationViewInfo;

  // const animateInfoLondon = useSpring({
  //   config: { mass: 1, tension: 120, friction: 14 },
  //   top: props.locationView === 'london' ? '-3%' : '0',
  // });

  // const animateInfoNewyork = useSpring({
  //   config: { mass: 1, tension: 120, friction: 14 },
  //   top: props.locationView === 'newyork' ? '-3%' : '0',
  // });

  locationViewInfo =
    props.locationView === 'london' ? (
      <StyledLocationInfo>
       Riverside House
        <br />
        2A Southwark Bridge Road
        <br />
        London SE1 9HA
        <br />
        <a href="tel:+44-207-940-7310">T: +44 207 940 7310</a>
        <br />
        E: <a href="mailto:info@cherrythinking.com">info@cherrythinking.com</a>
      </StyledLocationInfo>
    ) : props.locationView === 'newyork' ? (
      <StyledLocationInfo>
        4 World Trade Center,
        <br />
        150 Greenwich Street, 48<sup>th</sup> Floor
        <br />
        New York, NY 10007
        <br />
        <a href="tel:+1-646-701-0044">T: +1 646 7010044</a>
        <br />
        E: <a href="mailto:info@cherrythinking.com">info@cherrythinking.com</a>
      </StyledLocationInfo>
    ) : (
      <StyledLocationInfo>
        An Error has occurred, please reload the page.
      </StyledLocationInfo>
    );

    const {width} = useWindowWidth();

  return (
        <>
          {width < 768 && (
            <StyledLocationPhone>
              <StyledLocationTitle>
                {props.locationView === 'london' ? 'London' : 'New York'}
              </StyledLocationTitle>
              {locationViewInfo}
              <StyledOtherOfficeContainer locationView={props.locationView}>
                {props.locationView === 'london' ? (
                  <StyledOtherOfficeLon
                    onClick={() =>
                      props.locationView === 'london'
                        ? props.changeLocation('newyork')
                        : props.changeLocation('london')
                    }
                  >
                    New York
                  </StyledOtherOfficeLon>
                ) : (
                  <StyledOtherOfficeNY
                    onClick={() =>
                      props.locationView === 'london'
                        ? props.changeLocation('newyork')
                        : props.changeLocation('london')
                    }
                  >
                    London
                  </StyledOtherOfficeNY>
                )}

                {props.locationView === 'london' ? (
                  <StyledNextArrow
                    onClick={() =>
                      props.locationView === 'london'
                        ? props.changeLocation('newyork')
                        : props.changeLocation('london')
                    }
                  >
                    <RightArrow />
                  </StyledNextArrow>
                ) : (
                  <StyledBackArrow
                    onClick={() =>
                      props.locationView === 'london'
                        ? props.changeLocation('newyork')
                        : props.changeLocation('london')
                    }
                  >
                    <LeftArrow />
                  </StyledBackArrow>
                )}
              </StyledOtherOfficeContainer>
            </StyledLocationPhone>
          )}

          {width >= 768 && (
            <StyledLocation>
              <LocationPanel>
                <StyledLocationSplitInfo
                  // style={{
                  //   height: props.locationView === 'london' ? '104%' : '100%',
                  //   transform:
                  //     props.locationView === 'london'
                  //       ? 'translateY(-3%)'
                  //       : 'none',
                  // }}
                  background={
                    props.locationView === 'london'
                      ? theme.staticLinearGradientLeft
                      : 'white'
                  }
                  color={props.locationView === 'london' ? 'white' : '#a5a5a5'}
                  gridcol="1 / span 1"
                  msgridcol="1"
                  msgridspan="1"
                  zindex={props.locationView === 'london' ? '2' : '1'}
                  onClick={() =>
                    props.locationView === 'newyork'
                      ? props.changeLocation('london')
                      : null
                  }
                >
                  <div>
                    <StyledLocationTitle>London</StyledLocationTitle>
                    <StyledLocationInfo>
                      Riverside House
                      <br />
                      2A Southwark Bridge Road
                      <br />
                      London SE1 9HA
                      <br />
                      <a href="tel:+44-207-940-7310"> T: +44 207 940 7310</a>
                      <br />
                      E:{' '}
                      <a href="mailto:info@cherrythinking.com">
                        info@cherrythinking.com
                      </a>
                    </StyledLocationInfo>
                  </div>
                </StyledLocationSplitInfo>
              </LocationPanel>
              <LocationPanel>
                <StyledLocationSplitInfo
                  // style={{
                  //   height: props.locationView === 'newyork' ? '104%' : '100%',
                  //   transform:
                  //     props.locationView === 'newyork'
                  //       ? 'translateY(-3%)'
                  //       : 'none',
                  // }}
                  background={
                    props.locationView === 'newyork'
                      ? theme.staticLinearGradientRight
                      : 'white'
                  }
                  gridcol="2 / span 1"
                  msgridcol="2"
                  msgridspan="1"
                  color={props.locationView === 'newyork' ? 'white' : '#a5a5a5'}
                  zindex={props.locationView === 'newyork' ? '2' : '1'}
                  onClick={() =>
                    props.locationView === 'london'
                      ? props.changeLocation('newyork')
                      : null
                  }
                >
                  <div>
                    <StyledLocationTitle>New York</StyledLocationTitle>
                    <StyledLocationInfo>
                      4 World Trade Center,
                      <br />
                      150 Greenwich Street, 48<sup>th</sup> Floor
                      <br />
                      New York, NY 10007
                      <br />
                      <a href="tel:+1-646-701-0044">T: +1 646 7010044</a>
                      <br />
                      E:{' '}
                      <a href="mailto:info@cherrythinking.com">
                        info@cherrythinking.com
                      </a>
                    </StyledLocationInfo>
                  </div>
                </StyledLocationSplitInfo>
              </LocationPanel>
            </StyledLocation>
          )}
        </>
  );
};

export default MapContainerInfo;
